@import "../../scss/base.scss";

.section_1, .section_2{
  box-sizing: border-box;
    width: 100%;
    max-width: 1074px;
    margin: 0px auto;
    padding: 20px 0;
    @include tablet{
      padding: 20px 30px;
    }
    @include mobile {
      padding: 0px;
      
    }
  }
  .section_2{
    padding: 15px 0;
    @include mobile{
      margin-top: 20px;
      padding: 15px;
    }
    .titleSection{
      font-size: 25px;
      line-height: 32px;
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-weight: bold;
      text-decoration: underline;
      text-underline-offset: 2px;
      color: #393939;
      cursor: pointer;
      @include mobile{
        font-size: 18px;
        line-height: 23px;
        display: flex;
        align-items: center;
      }
    }
}