@import "../../scss/base.scss";

.card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
   margin: 20px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .card__Post {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      flex-direction: row;
    }
    position: relative;
    width: 100%;
    @include mobile{
      padding: 0 0 0 10px;
    }
    .card__Post_image {
      width: 100%;
      height: 170px;
      border-radius: 8px;
      overflow: hidden;
      @media (max-width: 768px) {
        width: 100%;
        padding-right: 20px;
        max-width: 320px;
      }
      @media ( max-width: 540px){
        max-width: 200px;
      }
      @include mobile{
        max-width: 115px;
        padding-right: 10px;
        height: 115px;
        span{
          width: 100%;
          height: 115px !important;
          img{
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }
    .card__Post_wrapperInfo{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;
      justify-content: space-between;
      @media ( max-width: 768px)
      {height: 100%;        }
      @include mobile {
      justify-content: flex-start;
      }
    


    .card__Post_time {
      color: #393939;
      font-size: 16px;
      margin-top: 5px;
      margin-bottom: 5px;
      @include mobile {
        font-family: 'Circular Std', Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        margin-top: 5px;
      }
    }
    .card__Post_title {
      a{
        color: inherit;
        text-decoration: none;
      }
      color: #393939;
      font-weight: 700;
      font-size: 20px;
      line-height: 25.3px;
      font-family: 'Circular Std Bld', Helvetica, Arial, sans-serif;
      @media (max-width: 980px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
       @media ( max-width: 420px){
         font-size: 16px;
         line-height: 20.24px;
         width: 210px;
         height: auto;
      }
    }
    .card__Post_read {
      a{
        color: inherit;
        font-size: 16px;
        @include mobile{
          font-size: 14px;
        }
        
      }
      color: #00ad66;
      font-weight: 700;
       text-underline-offset: 2px;
  
    }
    }
  }
}
