@import "../../scss/base.scss";

.article {
  margin: 20px 0;
  width: 100%;
  float: left;

  .data {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }

    .link {
      cursor: pointer;
      font-size: 14px;
      text-transform: capitalize;

      a {
        color: inherit;
        text-decoration: none;
        text-transform: capitalize;

        &:hover {
          p {
            color: #00ad66;
          }
        }
      }

      p {
        margin-bottom: 0px;
        text-transform: capitalize;
        color: #393939;
      }
    }

    .grey {
      cursor: pointer;
      font-size: 14px;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: #00000022;
        }
      }

      p {
        margin-bottom: 0px;
        color: #00000022;
      }
    }
  }

  .title {
    margin: 20px 0;
    font-family: Circular Std, Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    color: #393939;
    margin: 0 0 10px 0;

    @include mobile {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 10px 0;
    }

    .link {
      font-family: Circular Std, Helvetica, Arial, sans-serif;
      text-decoration: underline;
      text-underline-offset: 2px;
      font-size: 20px;
      line-height: 25px;
      font-weight: bold;
      color: #393939;

      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .articleContent {
    position: relative;
    margin-bottom: 25px;

    .sub {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
  }

  .view {
    position: relative;
    cursor: pointer;


    p {
      color: #393939;
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 900;
      margin: 0;
      display: flex;
      align-items: center;
      span > img{
        margin-left: 5px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        @include mobile{
          margin-top: 0;
        }
      }
      @include mobile {
        font-size: 14px;
      }
    }
  }

  .relatedStatesContainer {
    box-sizing: border-box;
    margin: 0 auto;


    h2 {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 25px;
      line-height: 32px;
      color: #393939;

      @include mobile {
        font-size: 18px;
        line-height: 23px;
      }
    }

    h3 {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 30px;
      color: #393939;

      @include mobile {
        margin: 0;
      }
    }

    .breedStates {
      display: flex;
      flex-wrap: wrap;
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;

      @include mobile {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 10px;
      }

      .noMatch {
        width: calc(100% / 4);
        margin: 0;

        @include mobile {

          font-family: "Circular Std", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          width: calc(100% /2);
        }

        a {
          color: rgba(57, 57, 57, 0.6);
          text-decoration: none;

        }

        a:hover {
          color: #00ad66;
        }
      }

      .match {
        width: calc(100% / 4);
        margin: 0;

        @include mobile {
          font-family: "Circular Std", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          width: calc(100% /2);
        }

        a {
          color: #393939;
          text-decoration: none;
        }

        a:hover {
          color: #00ad66;
        }
      }
    }

    button {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 30px;
      padding: 0;
      /* v2/black */
      color: #393939;
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-color: #393939;
      margin-top: 15px;
      position: relative;
      text-decoration-line: underline;
      text-underline-offset: 2px;

      p {
        margin-bottom: 0;
        font-weight: 900;
        color: #393939;
        font-size: 16px;
        font-family: "Circular Std bld";

        @include mobile {
          font-size: 12px;
        }
      }
    }

    .noResult {
      display: flex;
      align-items: center;
      height: 60px;
      margin-bottom: 20px;
      /* BG/Aqua green */
      background: #c6f6e3;
      mix-blend-mode: normal;
      border-radius: 8px;

      p {
        margin: 0;
        width: 100%;
        text-align: center;
        font-family: Circular Std Book, Helvetica, Arial, sans-serif;
        font-size: 18px;
        line-height: 23px;
        color: #393939;

        @include mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .titleWrapper {
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
      margin-bottom: 10px;
    }

    h2 {
      @include title;
      margin: 0;

      @include tablet {
        margin-bottom: 10px;
      }
    }

    .link {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      text-decoration: underline;
      text-underline-offset: 2px;
      font-size: 25px;
      line-height: 25px;
      font-weight: bold;
      color: #393939;

      @include mobile {
        font-size: 20px;
        line-height: 20px;
      }
    }

    input {
      border: 1px solid #393939;
      border-radius: 60px;
      height: 40px;
      width: 30%;
      padding: 0 20px;
      background: url("/landing/search_icon.svg");
      background-repeat: no-repeat;
      background-position-y: 7px;
      background-position-x: 95%;

      @include tablet {
        width: unset;
      }
    }

    input,
    input::placeholder {
      color: #393939;
      font-family: Circular Std Book, Arial, Helvetica, sans-serif;
      font-size: 18px;
      line-height: 23px;

      @include tablet {
        font-size: 14px;
        line-height: 18px;
      }
    }

    input:focus {
      background: unset;
      outline: none;
    }

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1.5em;
      width: 1.5em;
      border-radius: 50em;
      background: url('/landing/input_close.svg') no-repeat 30% 50%;
      background-size: contain;
      opacity: 0;
      pointer-events: none;
      cursor: pointer;

      @include mobile {
        height: 2em;
        width: 2em;
      }
    }

    input[type="search"]:focus::-webkit-search-cancel-button {
      opacity: 1;
      pointer-events: all;
    }

  }
}

.marginBottom {
  margin-bottom: 60px;

  @include mobile {
    margin-bottom: 30px;
  }
}