@import "../../scss/base.scss";

.article {
  margin: 20px 0 0;
  width: 100%;
  float: left;
  @include mobile{
    margin: 20px 0 0px;
  }
  .data {
    display: flex;
    flex-wrap: wrap;
    font-family: Circular Std Book, Helvetica, Arial, sans-serif;
    line-height: 24px;
    .link {
      cursor: pointer;
      font-size: 16px;
      text-transform: capitalize;
      width: calc(100% /4);
      margin: 0;
      @include mobile {
        font-family: 'Circular Std', Helvetica, Arial, sans-serif;;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        width: calc(100% /2);
      }
      a {
        color: inherit;
        text-decoration: none;
        text-transform: capitalize;
        &:hover {
          p{ color: #00ad66;}
        }
      }
      p{ margin: 0; text-transform: capitalize;  color: #393939; }
    }
    .grey {
       cursor: pointer;
      font-size: 16px;
      text-transform: capitalize;
      width: calc(100% /4);
      margin: 0;
      @include mobile {
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        width: calc(100% /2);
      }
      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          color: #00ad66;;
        }
      }
      p{ 
        margin: 0;
        color: rgba(57, 57, 57, 0.6);
        &:hover {
          color: #00ad66;;
        }
      }
    }
  }
  .title {
    font-family: Circular Std, Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    color: #393939;
    margin: 0 0 10px 0;
    @include mobile {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 10px 0;
    }
     .link{
      font-family: Circular Std, Helvetica, Arial, sans-serif;
      text-decoration: underline ;
      text-underline-offset: 2px;
      font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  color: #393939;
  @include mobile {
      font-size: 16px;
      line-height: 20px;
  }

  }
  }
  .articleContent{
    position: relative;
    margin-bottom: 10px;
    @include mobile{
      margin-bottom: 10px;
    }

    
    .sub{
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
  }
  .view {
    position: relative;
    cursor: pointer;

    p {
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: 900;
      color: #393939;
      font-size: 16px;
      font-family: Circular Std bld,Helvetica,Arial,sans-serif;
      @include mobile{
        font-size: 14px;
        margin: 0
      }

    }
  }
}

.marginBottom{
  margin-bottom: 80px;
}