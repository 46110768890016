@import "../../scss/base.scss";

.banner__wrapper {
  position: relative;

  .banner {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
    @include mobile {
      padding-top: 70px;
      height: 150px;
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 150px;
      position: absolute;
      bottom: 0;
      border-radius: 10px;
      background-color: #C6F6E3;
      @media (max-width: 860px) {
        bottom: 0px;
      }
    }
    .banner__image {
      width: 100%;
      height: 100%;
      max-height: 223px;
      max-width: 317px;
      margin-left: 60px;
      order: 1;
      transform: translate(0px,0px);
     @media (max-width: 460px) {
        width: 100%;
        height: 100%;
        transform: translateX(130px)
      }
      @media (max-width: 700px) {
        margin-left: 0px;
        width: 100%;
        height: 100%;

        transform: translateX(100px)
      }
      @include mobile{
         transform: translate(110px, -72px);
         position: absolute;

         img{
            width: 100%;
            height: 100%;
         }
        }
        @media ( max-width: 460px) {
          transform: translate(120px, -40px);
           max-width: 72%;
          position: absolute;
           img{
            width: 100%;
           
            height: 100%;
         }
        }
        @media ( max-width: 360px) {
          transform: translate(120px, -28px);
           max-width: 70%;
          position: absolute;
           img{
            width: 100%;
           
            height: 100%;
         }
        }
    }
    .banner__info {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
      position: relative;
      order: 2;
      @media (max-width: 860px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        width: 60%;
      }
      @include mobile {
        padding: 0px;
         margin: 40px 0px 10px;
         position: absolute;
         top: 40px;
         left: 20px;
         
         }
      .banner__info_wrapper {
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
        @media (max-width: 860px) {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
          margin-left: 40px;
        }
        @include mobile{
          line-height: inherit;
          margin: 0;
        }
        .banner__info_title {
          font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
          font-weight: bold;
          font-size: 35px;
          font-style: normal;
          line-height: 44px;
          color: #393939;
          margin: 0;
          @media (max-width: 860px) {
            font-size: 30px;
          }
          @include mobile{
            font-size: 20px;
            line-height: inherit;
          }
        }
        .banner__info_text {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          margin: 10px 0;
          color: #393939;
          @media (max-width: 580px) {
           font-size: 14px;
           font-weight: 400;
           line-height: 15px;
           }
           @media ( max-width: 460px){
             max-width: 70%;
           }
        }
      }
    }
    .banner__info_handle {
      margin-bottom: 30px;
       @media (max-width: 580px) {
         margin-bottom: 0;
         }
      button {
        width: 240px;
        height: 50px;
        border-radius: 75px;
        transform: matrix(1, 0, 0, 1, 0, 0);
        font-size: 18px;
        @media (max-width: 580px) {
           width: 160px;
           height: 40px;
           font-size: 14px;
           margin-bottom: 0;
           }
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
