@import "../../scss/base.scss";

.categoriesListContainer {
    margin-top: 20px;
    width: 100%;
    float: left;
    .data {
        position: relative;

        .title {
            font-family: Circular Std, Helvetica, Arial, sans-serif;
            font-size: 20px;
            line-height: 25px;
            font-weight: bold;
            color: #393939;
            margin: 0 0 10px 0;
            @include mobile {
                font-size: 16px;
                line-height: 20px;
                margin: 0 0 10px 0;
            }
            .link{
                font-family: Circular Std, Helvetica, Arial, sans-serif;
                text-decoration: underline ;
                text-underline-offset: 2px;
                font-size: 20px;
            line-height: 25px;
            font-weight: bold;
            color: #393939;
            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }

            }
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            .link {
                cursor: pointer;
                font-size: 16px;
                text-transform: capitalize;
                width: calc(100% / 4);
                margin: 0;
                @include mobile {
                    width: calc(100% / 2);
                }
                a {
                    text-decoration: none;
                    p {
                        font-family: Circular Std, Helvetica, Arial, sans-serif;
                        font-size: 16px;
                        line-height: 24px;
                        color: #393939;
                        margin: 0;
                        @include mobile {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }
                a:hover {
                    p {
                        color: #00ad66;
                    }
                }
            }
        }
    }
}
